<template>
  <Header />


  <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:50px; padding-bottom: 50px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: right;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-4"></div>
        <div class="col-md-4" style="border-left: solid 1px #ddd; padding: 20px;">
          
             <h6>Contact Us</h6>
          <router-link to="/" class="btn "> <i class="bi bi-envelope color-1"></i> conference@kogs.or.ke </router-link> <br />
          <router-link to="/" class="btn "> <i class="bi bi-telephone color-1"></i> +254 726 639621 </router-link> <br />
          <router-link to="/" class="btn ">  <i class="bi bi-globe color-1"></i> www.kogs.or.ke </router-link> <br />

        </div>
        
        
      </div>
      
    </div>


  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
        
      }
    },

    methods : {

    }

  }

</script>