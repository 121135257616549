<template>
  <Header />


  <section style="background-color:#970072; padding: 10px;">
    

    <div class="container" style="text-align:center;">
      <h6 class="font-3 text-white">  49th KOGS Annual Scientific Congress  </h6>
    </div>

  </section>


  <section style="padding-top:50px; padding-bottom: 50px; background-image: url('/assets/images/hero-background-icons.jpeg'); background-size: contain; background-position: right;">
    
    <div class="container">

      <div class="row">

        <div class="col-md-12" style="text-align:center;">
          
          <a href="https://www.cphdev.org/" target="_blank"> <img src="/assets/images/cphd-logo.png" style="height: 50px; margin-right: 20px;"> </a>

             <a href="https://healthiummedtech.com/" target="_blank"> <img src="/assets/images/healthium-logo-1.png.webp" style="height: 50px; margin-right: 20px;"> </a>

                <a href=" https://bsvgroup.com/" target="_blank"> <img src="/assets/images/bsvlogo-1-140x53.webp" style="height: 50px;"> </a>

        </div>
          
      </div>
      
    </div>


  </section>


<Footer />

</template>


<script>
  import Header from './layouts/Header'
  import Footer from './layouts/Footer'
  import Swal from 'sweetalert2'
  export default{

    name : 'home',
    components : { Header, Footer },
    data(){
      return{
        
      }
    },

    methods : {

    }

  }

</script>